import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import styles from './CustomRadio.module.css';

interface Props {
  id: string;
  value?: string;
  name?: string;
  label?: string;
  iconName?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onClick?: any;
  className?: string;
  index?: number;
  link?: string;
}

export const CustomRadio = ({
  id,
  name,
  value,
  label,
  defaultChecked,
  iconName,
  className,
  onClick,
  link,
  index,
  checked,
  ...rest
}: Props) => {
  const content = label && (
    <>
      {iconName && <Icon name={iconName} customId={label} />}
      <span className="mb4">{label}</span>
    </>
  );

  const labelClass = cx('db relative pointer', styles.radioContainer);

  const radioClass = cx(
    'flex absolute f11 top-0 br-100 ba flex-column items-center bg-transparent  h-100 w-100 justify-center ba br-100',
    { 'white b--white': !className },
    className,
    styles.radioCheck,
  );

  const handleInputChange = () => {
    return onClick(index, link);
  };

  return (
    <label htmlFor={id} className={labelClass}>
      <input
        type="radio"
        className="dn radio"
        name={name}
        id={id}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={handleInputChange}
        value={value}
        {...rest}
      />
      <span className={radioClass}>{content}</span>
    </label>
  );
};
