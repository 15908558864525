import get from 'lodash/get';

export const getBannersByPosition = (banners: any[], position: string) =>
  (banners ?? []).filter(({ position: bannerPosition }) => bannerPosition === position);

export const getBannersByLocale = (banners: any[], unformattedLocale: string) => {
  const [locale] = (unformattedLocale ?? '').split('/');
  return (banners ?? []).filter(
    ({ city }) => city && city.find(({ city_name }: any) => city_name === locale),
  );
};

export const getDefaultBanners = (banners: any[]) =>
  (banners ?? []).filter(
    ({ city, position }) =>
      city === null &&
      position !== 'mainBannerFooter' &&
      position !== 'regularBannerOne' &&
      position !== 'regularBannerSecond',
  );

const bannerNormalizer = (banner: any) => ({
  imageUrlLarge: get(banner, 'large_image.data.full_url', ''),
  imageUrlSmall: get(banner, 'small_image.data.full_url', ''),
  position: banner.position,
  link: banner.link,
  city: banner.city,
});

export const bannersHandler = ({
  banners,
  position,
  unformattedLocale,
}: {
  banners: [];
  position: string;
  unformattedLocale: string;
}) => {
  const pageBanners = getBannersByPosition(banners, position);
  const defaultBanners = getDefaultBanners(pageBanners);
  const localizedBanners = getBannersByLocale(pageBanners, unformattedLocale);
  return [...localizedBanners, ...defaultBanners].map(bannerNormalizer);
};
