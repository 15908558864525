import { axiosInstance } from '../../../../../services/axios-instance';

export const TopStores = {
  getTopStores: async (param: any) => {
    const city = param.split('/');
    return await axiosInstance
      .get(`user/bestStores/main_page/4?city=${city[0]}&state=${city[1]}`)
      .then(res => res)
      .catch(err => console.error(err));
  },
};
