import React, { useState } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { CustomRadio } from '../../../../components/CustomRadio';
import { RefineSearch } from '../../../../components/RefineSearch';
import { RefineSearchSelect } from '../../../../components/RefineSearchSelect';
import { dataCategoryFilterHome } from '../../../../services/mocks';
import styles from './SectionOne.module.css';

const Title = () => (
  <>
    <h1 className="dn db-ns fw2 f30 f36-m f36-l white ma0 mb16 pl16 pl0-ns tc">
      Busque agora no <br />
      <span className="fw7">maior site de veículos da região.</span>
    </h1>
    <h1 className="db dn-ns fw2 f26 white ma0 mb16 pl16 pl0-ns">
      Busque agora <br /> no
      <span className="fw7 pl4">
        maior site de <br /> veículos da região.
      </span>
    </h1>
  </>
);

export const SectionOne = () => {
  const desktopUrl = `${process.env.FTP_IMAGENS}images/home/fundo-home.jpg`;
  const mobileUrl = `${process.env.FTP_IMAGENS}images/home/fundo-home-mobile.jpg`;
  const [customDataIcons, setCustomDataIcons] = useState(dataCategoryFilterHome.slice(0, 4));
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [hideOptions, setHideOptions] = useState(false);
  const isMobile = useMediaQuery({
    query: '(max-device-width: 767px)',
  });
  const backgroundUrl = isMobile ? mobileUrl : desktopUrl;

  const onProductCategoryClick = (index: number, link: string) => {
    navigate(link);
    setSelectedCategory(index);
  };

  const handleSpreadIcons = () => {
    setHideOptions(!hideOptions);
    setCustomDataIcons(dataCategoryFilterHome);
  };
  const containerSectionOne = cx('relative cover bg-center', styles.containerSectionOne);

  const boxCustomRadio = cx(
    'white flex justify-center justify-center-ns pb16 pb24-m pb24-l overflow-x-auto overflow-hidden-ns',
    {
      'w-10': !hideOptions,
      'w-100': hideOptions,
      [styles.boxCustomRadioTotal]: hideOptions,
    },
    styles.boxCustomRadio,
  );

  const refineSearch = (
    <RefineSearch
      dataLink={dataCategoryFilterHome[selectedCategory]}
      selectedCategory={selectedCategory}
    />
  );

  const refineSearchSelect = <RefineSearchSelect category={selectedCategory} />;

  return (
    <div className={containerSectionOne} style={{ backgroundImage: `url(${backgroundUrl})` }}>
      <div className="h-100 flex flex-column items-center items-center-ns justify-between justify-center-ns pl0-ns">
        <div className="w-100 flex flex-column items-center items-center-ns mt72 mt0-ns">
          <Title />
          <div className={boxCustomRadio}>
            <div className="ph16 dn db-ns flex-ns">
              <RenderCustomRadio
                dataIcons={customDataIcons}
                identificator="defaultOpen"
                onProductCategoryClick={onProductCategoryClick}
              />
              {/* <CustomRadioOuther hideOptions={hideOptions} handleSpreadIcons={handleSpreadIcons} /> */}
            </div>
            <div className="ph16 flex db dn-ns">
              <RenderCustomRadio
                dataIcons={dataCategoryFilterHome}
                identificator="defaultClosed"
                onProductCategoryClick={onProductCategoryClick}
              />
            </div>
          </div>
        </div>
        <div>{refineSearchSelect}</div>
        <div className="dn db-ns">{refineSearch}</div>
      </div>
      <div className="db dn-ns">{refineSearch}</div>
    </div>
  );
};

interface Props {
  id: string;
  name?: string;
  label?: string;
  iconName: string;
  defaultChecked?: boolean;
  onClick?: any;
  className?: string;
  link: string;
}

const RenderCustomRadio: React.FC<{
  dataIcons: any;
  identificator: string;
  onProductCategoryClick: (index: number, link: string) => void;
}> = ({ dataIcons, identificator, onProductCategoryClick }) => {
  const onClick = (index: number, link: string) => onProductCategoryClick(index, link);

  return dataIcons.map((item: Props, idx: number) => {
    const { link, id, label } = item;
    return (
      <div key={item.id + item.label + identificator} className="mr8">
        <CustomRadio
          id={item.id + item.label + identificator}
          iconName={item.iconName}
          label={label}
          defaultChecked={idx === 0}
          onClick={onClick}
          name={`radio-${identificator}`}
          link={link}
          index={parseInt(id, 10)}
        />
      </div>
    );
  });
};

// const CustomRadioOuther: React.FC<{ hideOptions: boolean; handleSpreadIcons: () => void }> = ({
//   hideOptions,
//   handleSpreadIcons,
// }) => {
//   return hideOptions ? null : (
//     <CustomRadio
//       id="outhersIcons"
//       label="Outros"
//       iconName="SvgIconArrowRight"
//       onClick={handleSpreadIcons}
//     />
//   );
// };
