import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import logo from '../../components/Icons/src/icon-autoscarplay.svg';
import { axiosInstance } from '../../services/axios-instance';
import { Container } from '../Container';
import { WrapperSectionHome } from '../WrapperSectionHome';
import styles from './style.module.css';

const VideoCard = () => {
  const [videoLinks, setVideoLinks] = useState([]);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const fetchVideoLinks = async () => {
      try {
        const response = await axiosInstance.get('/play');
        setVideoLinks(response.data.youtubeLinks);
      } catch (error) {
        console.error('Erro ao buscar os links do YouTube:', error);
      }
    };

    fetchVideoLinks();
  }, []);

  return (
    <div className={`mb48 ${isMobile ? 'db' : 'db-ns'}`}>
      <Container>
        <div className={`${styles.text} abbey`}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </div>
        <div className={isMobile ? styles.mobileContainer : styles.container}>
          {videoLinks.slice(0, isMobile ? 1 : videoLinks.length).map((link, index) => (
            <div className={styles.card} key={index}>
              <iframe
                width="100%"
                height="100%"
                src={link}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                allowFullScreen
                title={`VideoCard${index + 1}`}
              />
            </div>
          ))}
        </div>
        <WrapperSectionHome
          linkTo="/anuncios-autoscarplay"
          textLinkTo="Explorar anúncios do AutoscarPlay"
          linkClass="pl16 pl0-ns pt24"
          linkAria="Explorar anúncios do AutoscarPlay"
          dataValue="AutoscarPlayAds"
        />
      </Container>
    </div>
  );
};

export { VideoCard };
