import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { LoaderCard } from './Loaders';

interface Props {
  children: React.ReactNode;
}

export const Visibility = ({ children }: Props) => {
  const [visible, setVisible] = useState(false);

  const onChange = (isVisible: boolean) => {
    if (!isVisible) {
      return;
    }

    setVisible(true);
  };

  const renderContent = ({ isVisible }: { isVisible: boolean }) => {
    if (!isVisible && !visible) {
      return <LoaderCard />;
    }
    return children;
  };

  return (
    <VisibilitySensor partialVisibility onChange={onChange}>
      {renderContent}
    </VisibilitySensor>
  );
};
