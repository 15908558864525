import { filter } from 'lodash';
import compact from 'lodash/compact';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';

interface Props {
  data: any;
  bannerPosition: string;
  locale?: string;
}

const getBannerByLocale = ({ data, bannerPosition, locale }: Props) => {
  const bannerByLocale = map(data, item => {
    const currentCityBanners = filter(item.city, city => city.city_name === locale);
    return item.position === bannerPosition && currentCityBanners.length ? item : null;
  });
  return compact(bannerByLocale);
};

const getBackupBanner = ({ data, bannerPosition }: Props) =>
  find(data, item => item.position === bannerPosition);

export const mainBannerFormatted = (data: any, locale: string) => {
  const mainBannerByLocale = getBannerByLocale({
    data,
    bannerPosition: 'mainBannerFooter',
    locale,
  });

  const mainBanner = getBackupBanner({ data, bannerPosition: 'mainBannerFooter' });
  const banner = mainBannerByLocale.length ? mainBannerByLocale[0] : mainBanner;

  return {
    imageUrlLarge: get(banner, 'large_image.data.full_url', ''),
    link: get(mainBanner, 'link', ''),
  };
};

export const regularBannerFormatted = (data: any, locale: string) => {
  const getBannerOneByLocale = getBannerByLocale({
    data,
    bannerPosition: 'regularBannerOne',
    locale,
  });

  const getBannerOne = getBackupBanner({ data, bannerPosition: 'regularBannerOne' });

  const bannerOne = getBannerOneByLocale.length ? getBannerOneByLocale[0] : getBannerOne;

  const getBannerTwoByLocale = getBannerByLocale({
    data,
    bannerPosition: 'regularBannerSecond',
    locale,
  });

  const getBannerTwo = getBackupBanner({ data, bannerPosition: 'regularBannerSecond' });
  const bannerTwo = getBannerTwoByLocale.length ? getBannerTwoByLocale[0] : getBannerTwo;

  return {
    firstBannerUrl: {
      image: get(bannerOne, 'large_image.data.full_url', ''),
      link: get(bannerOne, 'link', ''),
    },
    secondBannerUrl: {
      image: get(bannerTwo, 'large_image.data.full_url', ''),
      link: get(bannerTwo, 'link', ''),
    },
  };
};
