import { slugFormat } from '../../../utils/functions';
import { PARTS_NUMBER } from '../../../utils/productType';
import { vehicleTypeNameByNumber } from '../../VehicleRegistration/utils/functions';

export const urlFormatted = (data: any) => {
  const { id, city, state, model, partType, vehicleType, user, name } = data ?? {};

  if ((vehicleType && vehicleType === PARTS_NUMBER) || typeof partType !== 'undefined') {
    if (user?.city && user?.state) {
      return slugFormat(`/pecas/${user.state}/${user.city}/id${user.id}/${partType}/${name}/${id}`);
    }
    return slugFormat(`/pecas/id${user.id}/${partType}/${name}/${id}`);
  }

  const { name: modelName, brandName, version, type } = model ?? {};
  const newVersion = version ? `/${version}` : '';

  if (user) {
    return slugFormat(
      `/${vehicleTypeNameByNumber(type)}/${state}/${city}/id${
        user.id
      }/${brandName}/${modelName}${newVersion}/${id}`,
    );
  }
  return slugFormat(
    `/${vehicleTypeNameByNumber(
      type,
    )}/${state}/${city}/${brandName}/${modelName}${newVersion}/${id}`,
  );
};

export const formatUrlToEditPage = (data: any, isParts: boolean) => {
  const {
    Car: { vehicleType: type, name, city, brandName },
    Parts: { partType, name: partsName },
    id,
  } = data;
  if (isParts) {
    return slugFormat(`/pecas/${partType}/${partsName}/${id}`);
  }
  return slugFormat(`/${vehicleTypeNameByNumber(type)}/${city}/${brandName}/${name}/${id}`);
};
