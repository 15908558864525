import React from 'react';
import { Banner } from '../../../SectionBanner/Banner';

interface RegularBannerProps {
  image: string;
  link: string;
}

interface BannersProps {
  firstBannerUrl: RegularBannerProps;
  secondBannerUrl: RegularBannerProps;
}
interface Props {
  data: BannersProps;
}
export const RegularBanners = ({ data }: Props) => {
  const { firstBannerUrl, secondBannerUrl } = data;

  return (
    <>
      <Banner
        resetHeight
        className="w-100 h-auto mt12 mt0-ns br10 pointer"
        imgUrl={firstBannerUrl.image}
        link={firstBannerUrl.link}
        title="Serviços e acessórios"
        alt="Carro na oficina no fundo da imagem. Frase do banner: Tudo para o seu veículo. Pneus, funilaria e pintura, mecânica, lava-jato, acessórios e muito mais."
        width={510}
        height={180}
      />
      <Banner
        resetHeight
        className="w-100 h-auto mt12 mt12-ns mt24-m mt24-l br10 pointer"
        imgUrl={secondBannerUrl.image}
        link={secondBannerUrl.link}
        alt="Demonstração de anúncios no site da Autoscar. Frase do banner: Crie e gerencie mais de 250 anúncios."
        title="Gerencie anúncios"
        width={510}
        height={180}
      />
    </>
  );
};
