import React, { useState } from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import { Base } from '../Link/Base';
import { Ripple } from '../Ripple';
import styles from './CardCategory.module.css';

interface DataType {
  cardTitle: string;
  iconName: string;
  iconHover: string;
  url: string;
}

interface Props {
  data: DataType;
  isMobile: boolean;
}
export const CardCategory = ({ data, isMobile }: Props) => {
  const { cardTitle, iconName, iconHover, url } = data;
  const [hover, setHover] = useState(false);
  const cardClass = cx(
    'flex overflow-hidden bg-white br10 pt16 pt24-l pr12 pointer',
    styles.cardClass,
    {
      [styles.boxShadowRed]: hover,
    },
  );
  const imgCardClass = cx('w-100 h-100 relative', styles.imgCardClass);
  const onMouseOver = () => {
    setHover(true);
  };
  const onMouseLeave = () => {
    setHover(false);
  };
  const handleIcon = () => {
    const icon = hover ? iconHover : iconName;
    return <Icon name={icon} className="absolute right-0 bottom-0" />;
  };
  return (
    <div className={styles.wrapperCard}>
      <Ripple rippleSlow>
        <Base
          to={url}
          className="no-underline"
          title={cardTitle}
          target={isMobile ? '_self' : '_blank'}
        >
          <div className={cardClass} onMouseEnter={onMouseOver} onMouseLeave={onMouseLeave}>
            <p className="ma0 fw7 f16 f24-l lh-title pl8 pl12-l mb16-l abbey">{cardTitle}</p>
            <div className={imgCardClass}>{handleIcon()}</div>
          </div>
        </Base>
      </Ripple>
    </div>
  );
};
