import { navigate } from 'gatsby';
import { isEmpty, filter, get } from 'lodash';
import { vehicleTypeNameByNumberStepOneHome } from '../../../modules/VehicleRegistration/utils/functions';

interface GetSearchQueryParams {
  searchBrand?: string;
  selectedCategory?: number | string;
  values?: any;
  currentYear?: number;
  inputValue?: string;
}

interface HandleSearchParams extends GetSearchQueryParams {
  searchedFromSidebar?: boolean;
}

export const getSearchQueryParams = ({
  searchBrand = '',
  selectedCategory = 1,
  values = null,
  currentYear = new Date().getFullYear() + 1,
  inputValue = '',
}: GetSearchQueryParams) => {
  const initialYear = get(values, 'initialYear', 0);
  const finalYear = get(values, 'finalYear', 0);
  const valuesPrice = get(values, 'price', {});

  const years =
    initialYear && finalYear && (initialYear !== 1957 || finalYear !== currentYear)
      ? `ano_modelo=${initialYear} a ${finalYear}`
      : null;

  const price = !isEmpty(valuesPrice)
    ? `preco=${valuesPrice.initialPrice} a ${valuesPrice.finalPrice}`
    : null;

  const type = selectedCategory
    ? `categoria=${vehicleTypeNameByNumberStepOneHome(selectedCategory)}`
    : null;

  const brand = searchBrand ? searchBrand : null;

  const search = inputValue ? `pesquisar=${inputValue}` : null;

  const queryParams = filter([years, price, type, brand, search], param => param !== null).join(
    '&',
  );

  return queryParams;
};

export const handleSearch = ({
  searchBrand = '',
  selectedCategory = 1,
  values = null,
  currentYear = new Date().getFullYear() + 1,
  inputValue = '',
  searchedFromSidebar = false,
}: HandleSearchParams) => {
  const queryParams = getSearchQueryParams({
    searchBrand,
    selectedCategory,
    values,
    currentYear,
    inputValue,
  });

  if (!searchedFromSidebar) {
    navigate(`/anuncios/busca/?${queryParams}`);
    return;
  }

  window.history.pushState(null, '', `/anuncios/busca/?${queryParams}`);
};

export const formatParamsSearch = (e: any) => {
  const { name, version, brandName } = e.value;
  const model = name ? `&modelo=${name}` : '';
  const paramVersion = version ? `&versao=${version}` : '';
  return `marca=${brandName}${model}${paramVersion}`;
};
