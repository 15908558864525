import { axiosInstanceWithAuth } from '../../../services/axios-instance';

export const spotlight = {
  getSpotlight: async (param: string) => {
    const [town, county] = param.split('/');

    return await axiosInstanceWithAuth
      .get('/user/bestStores/main_page/1', {
        params: { city: town, state: county },
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => res)
      .catch(err => console.error(err));
  },
};
