import React from 'react';
import cx from 'classnames';
import { GridCell } from '../../components/GridCell';
import { TextLinkTo } from './TextLinkTo';
import styles from './WrapperSectionHome.module.css';

interface Props {
  children: React.ReactNode;
  linkTo?: string;
  textLinkTo?: string;
  initialText?: string;
  bolderText?: string;
  restText?: string;
  classTitle?: any;
  classGrid?: any;
  resolutionTitle?: number[];
  linkClass?: string;
  linkAria?: string;
  renderFilterSpecificVehicle?: React.ReactNode;
  home?: boolean;
  dataValue?: string;
}

export const WrapperSectionHome = ({
  initialText,
  bolderText,
  restText,
  children,
  linkTo,
  textLinkTo,
  classTitle,
  classGrid,
  resolutionTitle = [10 / 12],
  linkClass,
  linkAria,
  renderFilterSpecificVehicle,
  home,
  dataValue,
}: Props) => {
  const renderTitleSection = () => {
    const title = cx(
      'abbey fw2 ma0',
      {
        'f26 f30-ns': !classTitle,
        [styles.title]: home,
      },
      classTitle,
    );

    const styleSectionTitle = cx(
      {
        dn: !bolderText && !restText && !initialText,
        'mb16 mb24-ns': !classGrid,
      },
      classGrid,
    );

    if (bolderText || restText || initialText) {
      const renderTitle = () => {
        if (home) {
          return (
            <>
              {initialText} <span className="fw6 nowrap">{bolderText}</span>{' '}
              <br className="dn-ns" />
              {restText}
            </>
          );
        }

        return (
          <>
            {initialText} <span className="fw6 nowrap">{bolderText}</span> {restText}
          </>
        );
      };

      return (
        <GridCell width={resolutionTitle} className={styleSectionTitle}>
          <h2 className={title}>{renderTitle()}</h2>
          {renderFilterSpecificVehicle}
        </GridCell>
      );
    }
  };

  return (
    <>
      {renderTitleSection()}
      {children}
      <TextLinkTo
        textLinkTo={textLinkTo}
        linkTo={linkTo}
        className={linkClass}
        linkAria={linkAria}
        dataValue={dataValue}
      />
    </>
  );
};
