import React from 'react';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import map from 'lodash/map';
import random from 'lodash/random';
import { inject, observer } from 'mobx-react';
import Carousel from 'nuka-carousel';
import { GridCell } from '../../../../components/GridCell';
import { Link } from '../../../../components/Link';
import { MediaMedium, MediaMobile } from '../../../../components/MediaWrapper';
import { Banner } from './Banner';
import styles from './style.module.css';
import { bannersHandler } from './utils/adapterBanners';

interface Props {
  banners?: any;
  position: string;
  className?: string;
  locale?: any;
}
export const RenderSectionBanner: React.FunctionComponent<Props> = inject('locale')(
  observer(({ banners, className, position, locale }) => {
    const formattedBanners = bannersHandler({ banners, position, unformattedLocale: locale.city });

    const carouselAutoPlay = formattedBanners.length !== 1;
    const carouselIndex = random(0, formattedBanners.length - 1);

    const bannerClass = cx('db self-center mw-100 w-auto h-auto', {
      className,
    });

    const mapPhotos = (photos: any) => {
      return map(photos, item => {
        return (
          <Link to={item.link} title={item.link} key={item.link + carouselIndex}>
            <MediaMobile>
              <Banner
                imgUrl={item.imageUrlLarge}
                className={cx(bannerClass, styles.smallBanner)}
                alt="Banner de anunciante"
                title="Anúncio"
                link={item.link}
                width={1280}
                height={260}
              />
            </MediaMobile>
            <MediaMedium>
              <Banner
                imgUrl={item.imageUrlLarge}
                className={cx(bannerClass, styles.bigBanner)}
                alt="Banner de anunciante"
                title="Anúncio"
                link={item.link}
                width={1280}
                height={260}
              />
            </MediaMedium>
          </Link>
        );
      });
    };

    const mainClass = cx(
      'flex justify-center items-center',
      { [styles.mainClass]: !className },
      className,
    );
    return (
      <div className={mainClass}>
        <GridCell
          width={[0.96]}
          className={cx('w-100 flex justify-center', styles.sectionBannerClass)}
        >
          <Carousel
            autoplay={carouselAutoPlay}
            autoplayInterval={15000}
            wrapAround
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
            renderBottomCenterControls={null}
            slideIndex={carouselIndex}
            dragging={false}
            pauseOnHover={false}
          >
            {mapPhotos(formattedBanners)}
          </Carousel>
        </GridCell>
      </div>
    );
  }),
);

export const SectionBanner = ({ position, className }: Props) => {
  const bannersQuery = graphql`
    query {
      allDirectusBanner {
        nodes {
          position
          link
          city {
            city_name
          }
          large_image {
            data {
              full_url
            }
          }
          small_image {
            data {
              full_url
            }
          }
        }
      }
    }
  `;

  const renderSectionBanner = (data: any) => {
    const {
      allDirectusBanner: { nodes: banners },
    } = data;
    return <RenderSectionBanner position={position} className={className} banners={banners} />;
  };
  return <StaticQuery query={bannersQuery} render={renderSectionBanner} />;
};
