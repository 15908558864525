import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import { AdvertisementResponse } from '../../modules/AsideSearchBarAndContent/services';
import { PARTS_NUMBER, SERVICES_NUMBER } from '../../utils/productType';
import { Refine } from './Refine';
import styles from './RefineSearch.module.css';
import { Search } from './Search';
import { formatParamsSearch, getSearchQueryParams, handleSearch } from './utils';

interface IDataLink {
  iconName: string;
  id: string;
  label: string;
  listAllLinkLabel: string;
  link?: string;
}

interface Props {
  dataLink: IDataLink;
  advertisementSearchFilter?: any;
  selectedCategory?: any;
  handleClickSearch?: (category: number, inputValue: string) => void;
}

interface Values {
  initialYear: number;
  finalYear: number;
  price: {
    initialPrice?: number;
    finalPrice?: number;
  };
}

export const RefineSearch = inject('advertisementSearchFilter')(
  observer((props: Props) => {
    const currentYear = new Date().getFullYear() + 1;

    const initialValues = {
      initialYear: 1957,
      finalYear: currentYear,
      price: {},
    };

    const [baseYear, setBaseYear] = useState(1957);
    const [values, setValues] = useState<Values>(initialValues);
    const [loading, setLoading] = useState(false);
    const [refineOpen, setRefineOpen] = useState(false);
    const [searchBrand, setSearchBrand] = useState<string>();

    const { dataLink, selectedCategory } = props;

    const searchResultNumber = 0;

    const isRefineOpen = (is: boolean) => setRefineOpen(is);

    const getAdvertisementResponseSuccess = (res: any) => {
      setBaseYear(parseInt(Object.keys(res.data.filters.modelYear[0])[0], 10));
    };

    useEffect(() => {
      AdvertisementResponse.getAdvertisementResponse({ type: { eq: selectedCategory } }, 1)
        .then(getAdvertisementResponseSuccess)
        .catch(res => res);
    }, [selectedCategory]);

    useEffect(() => {
      if (!refineOpen) {
        setValues(initialValues);
      }
    }, [refineOpen]);

    const handleSubmit = (vals?: any) => {
      return vals;
    };

    const handleClickSearch = (category: number, inputValue?: string, brand?: any) => {
      setLoading(true);

      const brandToSearch = brand || searchBrand;

      if (inputValue && typeof inputValue === 'string') {
        handleSearch({ inputValue, selectedCategory: category });
      } else {
        handleSearch({
          searchBrand: brandToSearch,
          selectedCategory: category,
          values,
          currentYear,
        });
      }
    };

    const advancedSearchLink = useMemo(() => {
      const queryParams = getSearchQueryParams({
        selectedCategory,
        values,
        currentYear,
      });
      return `/anuncios/busca/?${queryParams}`;
    }, [selectedCategory, values, currentYear]);

    const handleClearSearchBrand = () => {
      setSearchBrand(null);
    };

    const handleChange = (e: any, makeSearch?: boolean, category?: number) => {
      const brand = formatParamsSearch(e);
      setSearchBrand(brand);
      if (makeSearch) {
        const categoryToSearch = category || 1;
        handleClickSearch(categoryToSearch, '', brand);
      }
    };

    const commonRefineProps = {
      textFirstLine: 'Não sabe o que procurar?',
      textSecondLine: 'Busque por preço e ano/modelo.',
      setRefineOpen: isRefineOpen,
      defaultOpen: refineOpen,
      searchResultNumber,
      dataLink,
      values,
      setValues,
      currentYear,
      loading,
      baseYear,
      selectedCategory,
      advancedSearchLink,
    };

    const refineAlign320 = cx(
      'z-0 w-100 absolute bottom-0 br3 br--top',
      {
        [styles.refineOpen]: refineOpen,
        [styles.refineClosed]: !refineOpen,
        [styles.hiddenRefine]:
          selectedCategory === SERVICES_NUMBER || selectedCategory === PARTS_NUMBER,
      },
      styles.refine,
      styles.br8Top,
    );

    const divSearch320Class = cx('z-1 absolute w-100', {
      absolute: refineOpen,
      [styles.absoluteSearchButton]: refineOpen,
      [styles.animationFadeInDelay4ms]: refineOpen,
      ph16: !refineOpen,
      ph0: refineOpen,
    });

    const refineAlign768 = cx('z-0 absolute w-100 flex flex-column items-center');

    const search = cx('z-1 absolute w-100', styles.search);

    const indexMainClass = cx(
      'w-100 relative flex flex-column',
      styles.mainH352,
      styles.indexMainClass,
    );
    const renderSearch = (
      <Search
        refineOpen={refineOpen}
        placeholder="Pesquise por marca e/ou modelo"
        handleClickSearch={handleClickSearch}
        value={searchBrand}
        onChange={handleChange}
        loading={loading}
        selectedCategory={selectedCategory}
        aria-label="Informe a marca ou modelo do veículo que deseja"
        searchBrand={searchBrand}
        values={values}
        currentYear={currentYear}
        clearSearchBrand={handleClearSearchBrand}
        isAutocomplete={selectedCategory !== PARTS_NUMBER}
      />
    );

    return (
      <div className={indexMainClass}>
        <div className="dn-ns db">
          <div className={divSearch320Class}>{renderSearch}</div>
          <div className={refineAlign320}>
            <Refine
              {...commonRefineProps}
              handleFilter={handleSubmit}
              initialPointing="up"
              handleSearch={handleClickSearch}
            />
          </div>
        </div>
        <div className="w-100 relative db-ns dn">
          <div className={search}>{renderSearch}</div>
          <div className={refineAlign768}>
            <Refine
              {...commonRefineProps}
              handleFilter={handleSubmit}
              handleSearch={handleClickSearch}
            />
          </div>
        </div>
      </div>
    );
  }),
);
