import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import { Link } from '../Link';
import styles from './WrapperSectionHome.module.css';

interface Props {
  linkTo?: string;
  textLinkTo?: string;
  className?: string;
  linkAria?: string;
  dataValue?: string;
}
export const TextLinkTo = ({ linkTo, textLinkTo, className, linkAria, dataValue }: Props) => {
  const dataValueMessage = dataValue || linkAria;
  const link = cx('w-100 mt24-ns f14 f18-ns fw6 no-underline pointer', styles.link);

  if (!textLinkTo) {
    return null;
  }
  const containerClass = cx(
    'w-100',
    {
      'mt24-ns': !className,
    },
    className,
  );
  return (
    <div className={containerClass}>
      <Link
        to={linkTo}
        className={cx('gtmGoToStoreHome', link)}
        title={linkAria}
        data-value={dataValueMessage}
      >
        {textLinkTo}
        <Icon
          name="SvgIconArrowRight"
          customId={`${linkAria}-icon`}
          width={10}
          height={10}
          className="ml12 gtmGoToStoreHome"
          data-value={dataValueMessage}
        />
      </Link>
    </div>
  );
};
