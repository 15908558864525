import clone from 'lodash/clone';
import { axiosInstance } from '../../../services/axios-instance';
import { FilterServices } from '../types';

export const AdvertisementResponse = {
  getAdvertisementResponse: async (
    filters: any,
    page: number,
    searchAd = '',
    orderAd: any = null,
    takenAd = 0,
  ) => {
    const search = searchAd ? encodeURI(searchAd) : null;
    const order = orderAd ? orderAd : null;
    const filter = clone(filters);
    const take = takenAd !== 0 && takenAd !== 12 ? takenAd : null;
    delete filter.search;
    const res = await axiosInstance.get('/advertisement/', {
      params: { filter, page, search, order, take },
    });

    return res;
  },

  getAdvertisementPartResponse: async (
    filters: any,
    page: number,
    searchAd = '',
    orderAd: any = null,
    takenAd = 0,
  ) => {
    const search = searchAd ? searchAd : null;
    const order = orderAd ? orderAd : null;
    const filter = clone(filters);
    const take = takenAd !== 0 && takenAd !== 12 ? takenAd : null;
    delete filter.search;
    delete filter.type;
    const res = await axiosInstance.get('/advertisement/partServices/parts', {
      params: { filter, page, search, order, take },
    });

    return res;
  },
};

export const AdvertisementPartServices = {
  getAdvertisementPartServices: async (
    filters: FilterServices | null = null,
    page = 0,
    search: string | null = null,
    take = 12,
  ) => {
    try {
      const res = await axiosInstance.get('/advertisement/partServices/', {
        params: { filter: filters, page, search, take },
      });

      return res;
    } catch (err) {
      return err.response;
    }
  },
};
