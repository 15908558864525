import React from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { nameInitials, ellipsis } from '../../utils/functions';
import { defaultConfigToApply } from '../../utils/randomPhoto';
import styles from './style.module.css';

interface SpecialOffersDataType {
  title: string;
  storeName: string;
  text?: string;
  logoUrl: string;
}
interface Props {
  data: SpecialOffersDataType;
  storeUrl: string;
}

export const CardSpecialOffers = ({ data, storeUrl }: Props) => {
  const { storeName, text, logoUrl } = data;
  const dataValueMessage = `${storeName} Ir Para a Loja - Home`;

  const storeEllipsis = ellipsis(storeName, 35);

  const imgCard = cx(
    'br-100 overflow-hidden b--gray ba contain bg-center bg-white',
    styles.imgCard,
    {
      pointer: storeUrl.length,
    },
  );

  const url = `${process.env.FTP_IMAGENS}${logoUrl}`;

  const storeStyle = logoUrl
    ? { backgroundImage: `url(${url})` }
    : { backgroundColor: defaultConfigToApply.bgColor };

  const initials = !logoUrl && (
    <p
      data-value={dataValueMessage}
      className="br-100 flex justify-center w-100 h-100 items-center f44 abbey fw1 ma0 gtmGoToStoreHome"
    >
      {nameInitials(storeName)}
    </p>
  );

  const redirect = () => navigate(storeUrl);

  return (
    <section>
      <div className="flex items-center justify-center mb8 mb16-ns">
        <div className={imgCard} style={{ ...storeStyle }} onClick={redirect}>
          {initials}
        </div>
      </div>
      <div className="w-100">
        <h3 className="f18 f24-m f30-l fw7 ma0 abbey tc lh-title">
          <span className="db fw2 ma0">{`${storeEllipsis}`}</span>
          <span className="db fw7 ma0">{text}</span>
        </h3>
      </div>
    </section>
  );
};
