import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Sector } from '../../../../components/SectionCreator';
import { Nodes, Category, DirectusCategoria } from '../../../../types/graphql';
import { categories } from './utils/categoryFormatter';

interface Props {
  data: Nodes<Category>;
}
export const RenderSectionThree = ({ data }: Props) => {
  const newData = categories(data);
  return (
    <div className="mt32 mt72-ns pt12-m pt12-l mb48 mb72-ns mb0-m mb0-l">
      <Sector list={newData} />
    </div>
  );
};

export const SectionThree = () => {
  const stepThreeData = graphql`
    query {
      allDirectusCategoria(sort: { fields: sort }) {
        nodes {
          link
          icon_hover
          icon
          name
        }
      }
    }
  `;
  const renderSectionThree = (data: DirectusCategoria) => {
    const {
      allDirectusCategoria: { nodes },
    } = data;
    return <RenderSectionThree data={nodes} />;
  };
  return <StaticQuery query={stepThreeData} render={renderSectionThree} />;
};
