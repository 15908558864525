import React, { useState } from 'react';
import cx from 'classnames';
import { graphql, StaticQuery } from 'gatsby';
import { map, filter, get } from 'lodash';
import { Collapse } from 'react-collapse';
import { CardCategory } from '../CardCategory';
import { Grid } from '../Grid';
import { GridCell } from '../GridCell';
import { Icon } from '../Icons';
import styles from './SectionCreator.module.css';
import { TitleSection } from './TitleSection';

interface CardProps {
  cardTitle: string;
  iconName: string;
  iconHover: string;
  url: string;
}
interface Props {
  isMobile?: boolean;
  list: CardProps[];
  data?: any;
}
export const RenderSectionHandler = ({ isMobile = true, list }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const mapCards = (Cards: CardProps[]) => {
    const gridClass = cx('pv12-m pa12-m pa12-l', styles.gridClass);
    return map(Cards, (card, idx) => {
      return (
        <GridCell key={`${card.cardTitle}-${idx}`} className={gridClass} width={[1 / 2, 2 / 12]}>
          <CardCategory data={card} isMobile={isMobile} />
        </GridCell>
      );
    });
  };
  const handleFixedItems = () => {
    const fixedItems = isMobile ? list.slice(0, 4) : list.slice(0, 6);
    return mapCards(fixedItems);
  };
  const handleCollapseCards = () => {
    const collapseItems = isMobile ? list.slice(4) : list.slice(6);
    return mapCards(collapseItems);
  };
  const controlSection = () => {
    setIsOpen(!isOpen);
  };
  const iconClass = cx('ml4 ml12-ns', {
    'rotate-180': isOpen,
  });
  const buttonClass = cx(
    'pt4 pt0-ns pl0 mt12-m fw6 f14 f18-ns lh-copy red bn bg-transparent items-center',
    styles.buttonClass,
    {
      dn: !handleCollapseCards().length,
      flex: handleCollapseCards().length,
    },
  );
  return (
    <div>
      <TitleSection title="Aqui " Spotlight="você encontra" />
      <div className={styles.negativeMargin}>
        <Grid className="flex flex-nowrap">{handleFixedItems()}</Grid>
        <Collapse isOpened={isOpen}>
          <Grid className="flex flex-nowrap">{handleCollapseCards()}</Grid>
        </Collapse>
      </div>
      <button className={buttonClass} onClick={controlSection}>
        Ver todas as categorias
        <Icon className={iconClass} name="SvgIconArrowDownBold" width={16} height={16} />
      </button>
    </div>
  );
};
export const SectionHandler = ({ isMobile = true, list }: Props) => {
  const menuData = graphql`
    query {
      allDirectusMenu {
        nodes {
          submenu_menu {
            name
            url_submenu
            icon
          }
          name_item_menu
          url_menu
          icon_menu
        }
      }
    }
  `;

  const renderSectionHandler = (data: any) => {
    const {
      allDirectusMenu: { nodes },
    } = data;
    const filteredSellNode = filter(nodes, (node: any) => node.name_item_menu === 'Vender');
    const sellNode = get(filteredSellNode, '[0].submenu_menu', []);
    return <RenderSectionHandler data={sellNode} isMobile={isMobile} list={list} />;
  };
  return <StaticQuery query={menuData} render={renderSectionHandler} />;
};
