import React from 'react';
import cx from 'classnames';
import _head from 'lodash/head';
import styles from './style.module.css';

interface Props {
  imgUrl: string;
  className?: string;
  resetHeight?: boolean;
  alt: string;
  title: string;
  link?: string;
  width?: number;
  height?: number;
}
export class Banner extends React.Component<Props> {
  render() {
    const { imgUrl, className, resetHeight, link, ...props } = this.props;
    const bannerClass = cx(className, {
      [styles.bannerClass]: !resetHeight,
    });

    const navigateLink = link || '/';
    const target = _head(link) === '/' ? '_self' : '_blank';

    return (
      <a href={navigateLink} target={target} className={bannerClass}>
        <img src={imgUrl} className={bannerClass} key={imgUrl} loading="lazy" {...props} />
      </a>
    );
  }
}
