import React from 'react';
import cx from 'classnames';
import { urlFormatted } from '../../modules/Home/utils/formattedUrl';
import { CardAd } from '../CardAd';
import { GridCell } from '../GridCell';
import styles from './style.module.css';

interface DataType {
  km: number;
  id: number;
  name: string;
  price: number;
  motor: number;
  carName: string;
  version: string;
  modelYear: number;
  brandName: string;
  urlPictures: string[];
  fabricationYear: number;
  city: string;
  state: string;
  type: any;
  user: { id: number };
}
interface Props {
  data: DataType;
  clientName: string;
}
export const Ad = ({ data, clientName }: Props) => {
  const {
    km,
    id,
    name,
    price,
    carName,
    modelYear,
    brandName,
    version,
    urlPictures,
    fabricationYear,
    state,
    city,
    type,
    user,
  } = data;
  const cardsClass = cx('mh6 mh12-m mh12-l', styles.cardsClass);
  const urlFomatted = urlFormatted({
    id,
    city,
    state,
    model: { name, brandName, version, type },
    user: { id: user?.id },
  });
  return (
    <GridCell width={1} className={cardsClass}>
      <CardAd
        km={km}
        price={price}
        carName={carName}
        modelYear={modelYear}
        clientName={clientName}
        urlPictures={urlPictures}
        fabricationYear={fabricationYear}
        url={urlFomatted}
      />
    </GridCell>
  );
};
