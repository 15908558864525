import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import { CardAd } from '../../../../components/CardAd';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { LoaderCard } from '../../../../components/Loaders';
import { WrapperSectionHome } from '../../../../components/WrapperSectionHome';
import { PARTS_NUMBER } from '../../../../utils/productType';
import { ShopAdsService } from '../../../Shop/services/ShopAdsService';
import { urlFormatted } from '../../utils/formattedUrl';
import { MayLikeServices, MayLikeParts } from './services/MayLikeServices';
import styles from './style.module.css';

interface Props {
  locale?: any;
  store?: {
    userId: number;
  };
}
export const SectionSix = inject('locale')(
  observer(({ locale, store }: Props) => {
    const [response, setResponse] = useState<any>();
    const [loading, setLoading] = useState(true);
    const { city } = locale;
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const isParts = url.includes('/pecas/');
    const linkTo = isParts ? '/anuncios/busca/?categoria=pecas' : '/anuncios/busca';

    useEffect(() => {
      const fetchData = async () => {
        if (isParts) {
          await MayLikeParts.getMayLikeParts()
            .then(res => {
              if (res.status === 200) {
                setResponse(res.data);
              }
            })
            .catch(err => err);
          setLoading(false);
        } else {
          if (!store) {
            await MayLikeServices.getMayLike(city)
              .then(res => {
                if (res.status === 200) {
                  setResponse(res.data);
                }
              })
              .catch(err => err);
          } else {
            await ShopAdsService.getShopAds({ idUser: store.userId, page: 0, take: 4 })
              .then(res => {
                setResponse(res.data);
              })
              .catch(err => err);
          }
          setLoading(false);
        }
      };
      fetchData();
    }, [city]);

    const renderOffersCards = () => {
      if (isParts) {
        return renderTopOffersPartsCards();
      }
      return renderTopOffersCards();
    };

    const renderTopOffersPartsCards = () => {
      return loading ? (
        <LoaderCard />
      ) : (
        map(response, (offer, idx) => {
          const { user, name, price, photoUrl, newPart } = offer;
          offer.vehicleType = PARTS_NUMBER;
          const newUrl = urlFormatted(offer);
          const userNameAd = get(user, user.type === 1 ? 'name' : 'fantasyName', '') || '';

          return (
            <GridCell
              key={`${name}-${idx}`}
              className="mb12 mb0-ns"
              width={[0.4792, 0.23713, 0.2313, 0.23594]}
            >
              <CardAd
                carName={`${name}`}
                clientName={userNameAd}
                price={price}
                urlPictures={photoUrl}
                url={newUrl}
                vehicleType={PARTS_NUMBER}
                isNew={newPart}
              />
            </GridCell>
          );
        })
      );
    };

    const renderTopOffersCards = () => {
      return loading ? (
        <LoaderCard />
      ) : (
        map(response, (offer, idx) => {
          const offerModel = get(offer, 'model', {});
          const { brandName, name, modelYear, fabricationYear, version } = offerModel;
          const { mileage, price, photoUrl, user } = offer;
          const newUrl = urlFormatted(offer);
          const userNameAd = get(user, user.type === 1 ? 'name' : 'fantasyName', '') || '';

          return (
            <GridCell
              key={`${name}--${idx}`}
              width={[0.4792, 0.23713, 0.2313, 0.23594]}
              className="mb12 mb0-ns"
            >
              <CardAd
                carName={`${brandName} ${name} ${version}`}
                modelYear={modelYear}
                fabricationYear={fabricationYear}
                km={mileage}
                clientName={userNameAd}
                price={price}
                urlPictures={photoUrl}
                url={newUrl}
              />
            </GridCell>
          );
        })
      );
    };

    if (!response || (response && !response.length)) {
      return null;
    }

    const cardsAlign = response.length > 3 ? 'justify-between' : ['justify-start', styles.section];

    return (
      <div className="mt32 mt72-ns pt8-m pt8-l mb48 mb72-ns mb0-m mb0-l">
        <WrapperSectionHome
          initialText="Você também "
          bolderText="pode gostar"
          linkTo={linkTo}
          textLinkTo="Ver mais opções"
          linkAria="Ir para página de mais sugestões"
          home
        >
          <Grid width={1} className={cx('pb12 pb0-ns', cardsAlign)}>
            {renderOffersCards()}
          </Grid>
        </WrapperSectionHome>
      </div>
    );
  }),
);
