import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import clone from 'lodash/clone';
import flattenDeep from 'lodash/flattenDeep';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { Icon } from '../Icons';
import { LinkBlack } from '../Link';
import { Select } from '../Select';
import styles from './RefineSearch.module.css';
import { search } from './services';
import { getSearchQueryParams } from './utils';
import { background } from 'styled-system';

interface Values {
  initialYear: number;
  finalYear: number;
  price: {
    initialPrice?: number;
    finalPrice?: number;
  };
}

interface Props {
  placeholder: string;
  refineOpen?: boolean;
  border?: boolean;
  value: string;
  loading?: boolean;
  handleClickSearch: (category: number, inputValue: string) => void;
  onChange: (
    value: any,
    makeSearch?: boolean,
    selectedCategory?: number,
    inputValue?: string,
  ) => void;
  selectedCategory: any;
  'aria-label': string;
  locationDropdown?: string;
  searchBrand: string;
  values: Values;
  currentYear: number;
  clearSearchBrand?: () => void;
  sidebarSearch?: boolean;
  handleGetParamsFromUrlAndSearch?: () => void;
  clearSearchInputValue?: boolean;
  isAutocomplete?: boolean;
  onSetSidebarOpen?: () => void;
}

export const Search = ({
  refineOpen,
  border,
  handleClickSearch,
  value,
  loading,
  placeholder,
  onChange,
  selectedCategory,
  locationDropdown,
  searchBrand,
  values,
  currentYear,
  clearSearchBrand,
  sidebarSearch,
  handleGetParamsFromUrlAndSearch,
  clearSearchInputValue,
  isAutocomplete = true,
  onSetSidebarOpen,
  ...props
}: Props) => {
  const [focus, setFocus] = useState(false);
  const [autocompleteList, setAutocompleteList] = useState<any>();
  const [inputValue, setInputValue] = useState('');
  const [makeSearch, setMakeSearch] = useState(false);
  const [findSearch, setFindSearch] = useState('');

  useEffect(() => {
    if (clearSearchInputValue) {
      setInputValue('');
    }
  }, [clearSearchInputValue]);

  const containerSearch = cx('flex bg-white br3', styles.h48, {
    'mh16 mh0-ns': refineOpen,
    'ba b--gray border-box': border,
  });

  const controlStyle = {
    border: '0px',
    height: '46px',
    width: '100%',
    '&:hover': null,
    background: 'none',
  };

  const handleChange = (e: any) => {
    onChange(e, true, selectedCategory, '');
  };

  const handleSelectFocus = () => setFocus(true);

  const handleSelectBlur = () => setFocus(false);

  const handleClearInput = () => {
    setInputValue('');
    setAutocompleteList([]);
  };

  const handleButtonClick = (e: any) => {
    e.preventDefault();

    if (handleClickSearch) {
      handleClickSearch(selectedCategory, inputValue);
    }
    if (screen.width <= 1024 && onSetSidebarOpen) {
      onSetSidebarOpen();
    }
  };

  // SEO improvements
  const advancedSearchLink = useMemo(() => {
    const queryParams = getSearchQueryParams({
      selectedCategory,
      inputValue,
    });
    return `/anuncios/busca/?${queryParams}`;
  }, [selectedCategory, inputValue]);

  const handleGetAutocompleteListSuccess = (models: any) => {
    const oldNames: any = [];
    const oldBrands: any = [];

    const uniqModels = uniqBy(models.data, (v: any) =>
      JSON.stringify([v.brandName, v.name, v.version]),
    );

    const selectList = map(uniqModels, ({ brandName, name, version }) => {
      const item = [
        { label: `${brandName} ${name} ${version}`, value: { brandName, name, version } },
      ];

      if (version) {
        if (oldNames.indexOf(name) === -1) {
          oldNames.push(name);
          item.unshift({
            label: `${brandName} ${name}`,
            value: { brandName, name, version: '' },
          });
        }
      }

      if (oldBrands.indexOf(brandName) === -1) {
        oldBrands.push(brandName);
        item.unshift({
          label: brandName,
          value: { brandName, name: '', version: '' },
        });
      }

      return item;
    });

    const flatSelectList = flattenDeep(selectList);

    setAutocompleteList(orderBy(flatSelectList, 'value.brandName'));

    if (makeSearch) {
      const onlyBrandName =
        findSearch.trim().toUpperCase() === flatSelectList[0].value.brandName.toUpperCase();
      setMakeSearch(false);

      if (flatSelectList.length && onlyBrandName) {
        return onChange(flatSelectList[0], true, selectedCategory, inputValue);
      }

      if (flatSelectList.length && flatSelectList.length >= 2 && !onlyBrandName) {
        return onChange(flatSelectList[1], true, selectedCategory, inputValue);
      }
    }
  };

  const handleOnInputChange = (valueToFind: string, action: any) => {
    setMakeSearch(false);

    if (valueToFind) {
      setFindSearch(clone(valueToFind));
    }

    if (!valueToFind) {
      setMakeSearch(true);
    }
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setInputValue(valueToFind);
    }

    if (valueToFind.length >= 3 && isAutocomplete) {
      search.autocompleteList(valueToFind, selectedCategory).then(handleGetAutocompleteListSuccess);
    }
  };

  const renderIcon = () => <Icon name="SvgIconSearch" />;

  const renderButton = () => {
    if (refineOpen) {
      return null;
    }

    const buttonClass = cx(
      'bn flex justify-center items-center br3 br--right pointer',
      styles.buttonClass,
      {
        [styles.buttonWhenInputFocus]: focus || value,
        [styles.buttonWhenInputNoFocus]: !focus && !value,
        'bg-alto': !focus,
      },
    );

    return (
      <button
        disabled={loading}
        className={buttonClass}
        onClick={handleButtonClick}
        title="Buscar"
        aria-label="Buscar"
      >
        {renderIcon()}
      </button>
    );
  };

  return (
    <>
      <div className={containerSearch}>
        <form onSubmit={handleButtonClick} className="w-100 flex">
          <Select
            noDropdownIndicator
            onInputChange={handleOnInputChange}
            options={autocompleteList}
            controlStyle={controlStyle}
            className="w-100"
            onChange={handleChange}
            onFocus={handleSelectFocus}
            onBlur={handleSelectBlur}
            placeholder={placeholder}
            locationDropdown={locationDropdown}
            inputValue={inputValue}
            selectedCategory={selectedCategory}
            searchBrand={searchBrand}
            currentYear={currentYear}
            clearInput={handleClearInput}
            clearSearchBrand={clearSearchBrand}
            {...props}
          />
          {renderButton()}
        </form>
      </div>
      <LinkBlack
        className="dn-ns f18 fw6 bn white pointer underline w-100 flex justify-center pointer mt8 pv8"
        resetClasses
        render={!refineOpen}
        title="Busca avançada"
        to={advancedSearchLink}
      >
        Busca avançada
      </LinkBlack>
    </>
  );
};
