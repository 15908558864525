import { axiosInstance } from '../../../services/axios-instance';

interface PropsParams {
  idUser: string | number;
  page: number;
  take: number;
  order?: any;
}

export const ShopAdsService = {
  getShopAds: async (params: PropsParams) => {
    const { idUser, page, take, order } = params;
    return await axiosInstance
      .get(`/advertisement/findByUser/${idUser}`, { params: { page, take, order } })
      .then(response => response)
      .catch(error => error.response);
  },

  getShopAdsParts: async (params: PropsParams) => {
    const { idUser, page, take } = params;
    return await axiosInstance
      .get(`/advertisement/partsServices/findPartsByUser/${idUser}`, {
        params: { page, take },
      })
      .then(response => response)
      .catch(error => error.response);
  },
};

export const FindService = {
  getServices: async (userId: number | string) => {
    return await axiosInstance
      .get(`/advertisement/findServiceByUserId/${userId}`)
      .then(response => response)
      .catch(error => error.response);
  },
};
