import { axiosInstance } from '../../../../../services/axios-instance';

export const TopOffersServicesByCity = {
  getTopOffers: async (param: any) => {
    const [town, county] = param.split('/');
    return await axiosInstance
      .get('/advertisement/bestOffers/main_page/4', { params: { city: town, state: county } })
      .then(response => response)
      .catch(error => error.response);
  },
};

export const TopOffersParts = {
  getTopPartsOffers: async () => {
    return await axiosInstance
      .get('/advertisement/parts/bestOffers/main_page/4')
      .then(response => response)
      .catch(error => error.response);
  },
};
