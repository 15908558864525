import get from 'lodash/get';
import map from 'lodash/map';
import { slugFormat } from '../../utils/functions';

export const treatAdvertsements = (response: any) => {
  const advertsements = get(response, 'data[0].advertsements', []);

  return map(advertsements, ({ price, model, description, mileage, photoURL, id, city, state }) => {
    const { name, version, brandName, modelYear, fabricationYear, motor, type } = model;
    const carName = `${brandName} ${name} ${version}`;
    return {
      id,
      name,
      price,
      motor,
      carName,
      version,
      modelYear,
      brandName,
      km: mileage,
      description,
      fabricationYear,
      urlPictures: photoURL,
      city,
      state,
      type: parseInt(type, 10),
      user: { id: response?.data[0]?.id },
    };
  });
};

export const treatStore = (response: any) => {
  const fantasyName = get(response, 'data[0].fantasyName', '');
  const companyName = get(response, 'data[0].companyName', '');
  const subtitle = fantasyName || companyName;
  const urlCompanyLogo = get(response, 'data[0].urlCompanyLogo', '');
  const town = get(response, 'data[0].city', '');
  const id = get(response, 'data[0].id', '');
  const state = get(response, 'data[0].state', '');

  const storeUrl = slugFormat(`/loja/${state}/${town}/${fantasyName}/${id}`);

  return {
    subtitle,
    urlCompanyLogo,
    storeUrl,
  };
};
