import get from 'lodash/get';
import map from 'lodash/map';

const formatterBestOffers = (advertsements: any, s3: any) => {
  return map(advertsements, (advertsement, indx) => {
    const url = get(advertsement, 'photoURL[0]', '');
    return {
      id: indx,
      url: url ? `${s3}thumbs/${url}` : '',
    };
  });
};

export const formattedTopStore = (data: any, s3: string | any) => {
  const topStore = map(data, item => {
    const { id, fantasyName, advertsements, urlCompanyLogo, urlFrontagePhoto, city, state } = item;
    return {
      id,
      storeName: fantasyName,
      bestOffers: formatterBestOffers(advertsements, s3),
      imageData: {
        url: urlCompanyLogo ? `${s3}thumbs/${urlCompanyLogo}` : '',
        title: fantasyName,
      },
      coverPhoto: urlFrontagePhoto ? `${s3}thumbs/${urlFrontagePhoto}` : '',
      storeLocation: {
        city,
        state,
      },
    };
  });
  return topStore;
};
