import React from 'react';
import { Banner } from '../../../SectionBanner/Banner';

interface BannerProps {
  imageUrlLarge: string;
  link: string;
}
interface Props {
  data: BannerProps;
}
export const MainBanner = ({ data }: Props) => {
  const { imageUrlLarge, link } = data;
  return (
    <Banner
      resetHeight
      className="w-100 h-auto br10 pointer"
      imgUrl={imageUrlLarge}
      link={link}
      title="Anuncie"
      alt="Mulher sorridente segurando a chave de um carro, sentada no banco do motorista. Frase do banner: Anunciou, vendeu. Mais de 1 milhão de visitas por mês."
      width={747}
      height={393}
    />
  );
};
