import _deburr from 'lodash/deburr';
import _lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';

export const categories = (data: any) => {
  return map(data, category => {
    const { name, icon, icon_hover, link } = category;

    const url = link.split('=')[0];
    const vehicleType = link.split('=')[1];

    const newLink = `${url}=${_lowerCase(_deburr(vehicleType))}`;

    return {
      cardTitle: name,
      iconName: icon,
      iconHover: icon_hover,
      url: newLink,
    };
  });
};
