import React, { Component } from 'react';
import { Container } from '../../components/Container';
import { ListCards } from '../../components/ListCards';
import { Visibility } from '../../components/Visibility';
import { VideoCard } from '../../components/YoutubeCard/VideoCard';
import { SectionBanner } from './components/SectionBanner';
import { SectionFive } from './components/SectionFive';
import { SectionOne } from './components/SectionOne';
import { SectionSeven } from './components/SectionSeven';
import { SectionSix } from './components/SectionSix';
import { SectionThree } from './components/SectionThree';
import { SectionTwo } from './components/SectionTwo';

export class Home extends Component {
  render() {
    return (
      <>
        <SectionOne />
        <div id="mainHome" className="w-100 bg-wild-sand pt48">
          <VideoCard />
          <ListCards />
          <Container>
            <Visibility>
              <SectionBanner position="pageInitialOne" />
            </Visibility>
            <SectionTwo />
            <SectionThree />
            <SectionFive />
            <SectionSix />
            <Visibility>
              <SectionBanner position="pageInitialTwo" />
            </Visibility>
            <SectionSeven />
          </Container>
        </div>
      </>
    );
  }
}
