import React from 'react';
import { Layout } from '../components/Layout';
import { Footer } from '../modules/Footer';
import { Home } from '../modules/Home';
import { Menu } from '../modules/Menu';

// Descomentar após configuração adsense
// import AdBlock from '../components/AdBlock';
// import AdSense from '../utils/AdSense';

const HomeAutosCar = () => {
  // AdSense(); Descomentar após configuração adsense

  return (
    <Layout>
      <Menu selectedItem="Vitrine" />
      <Home />
      {/* <AdBlock /> */}
      <Footer />
    </Layout>
  );
};

export default HomeAutosCar;
