import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { SectionHandler } from './SectionHandler';

interface CardProps {
  cardTitle: string;
  iconName: string;
  iconHover: string;
  url: string;
}
interface Props {
  list: CardProps[];
}
export const Sector = ({ list }: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <div>
      <SectionHandler list={list} isMobile={isMobile} />
    </div>
  );
};
