import React, { useState } from 'react';
import { useEffect } from 'react';
import Axios from 'axios';
import './refineSearchSelect.css';

export const RefineSearchSelect = (props: any) => {
  const [marca, setMarca] = useState<string>('');
  const [modelo, setModelo] = useState<string>('');

  const [modelos, setModelos] = useState<any>([]);
  const [marcas, setMarcas] = useState<any>([]);

  const setBrands = new Set();
  const setModels = new Set();

  useEffect(() => {
    Axios.get(
      `${process.env.BASE_API_DEV}/advertisement/options/autocomplete?search=&type=${props.category}`,
    ).then(response => setMarcas(response.data));
  }, []);

  useEffect(() => {
    if (props.category !== 7 && props.category !== 8) {
      Axios.get(
        `${process.env.BASE_API_DEV}/advertisement/options/autocomplete?search=&type=${props.category}`,
      ).then(response => setMarcas(response.data));
      setMarca('');
      setMarcas([]);
      setModelo('');
      setModelos([]);
    }
  }, [props.category]);

  useEffect(() => {
    Axios.get(
      `${process.env.BASE_API_DEV}/advertisement/options/autocomplete?search=${marca
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')}&type=${props.category}`,
    ).then(response => setModelos(response.data));
  }, [marca]);

  const handleSearch = () => {
    let tipo = '';

    switch (props.category) {
      case 1:
        tipo = 'carros';
        break;
      case 2:
        tipo = 'motos';
        break;
      case 3:
        tipo = 'onibus';
        break;
      case 4:
        tipo = 'carga';
        break;
      case 5:
        tipo = 'nautica';
        break;
      case 6:
        tipo = 'agricola';
        break;
      default:
        tipo = 'carros';
    }

    window.location.href = `/anuncios/busca/?categoria=${tipo}&marca=${marca}&modelo=${modelo}`;
  };

  const filterBrands = marcas.filter((marca: any) => {
    const duplicateBrands = setBrands.has(marca.brandName);
    setBrands.add(marca.brandName);
    return !duplicateBrands;
  });

  const filterModels = modelos.filter((modelo: any) => {
    const duplicateModels = setModels.has(modelo.name);
    setModels.add(modelo.name);
    return !duplicateModels;
  });

  const hidden: boolean = props.category === 7 || props.category === 8;

  return (
    <div id="search-select">
      <select
        name="marca"
        title="Marca"
        id="marca"
        value={marca}
        onChange={e => setMarca(e.target.value)}
        hidden={hidden}
      >
        <option value="" hidden>
          Marca
        </option>
        {filterBrands
          .sort((name1: any, name2: any) =>
            name1.brandName < name2.brandName ? -1 : name1.brandName > name2.brandName ? 1 : 0,
          )
          .map((marca: any) => (
            <option key={marca.brandName} value={marca.brandName}>
              {marca.brandName}
            </option>
          ))}
      </select>
      <select
        name="modelo"
        id="modelo"
        title="Modelo"
        value={modelo}
        disabled={!marca}
        onChange={e => setModelo(e.target.value)}
        hidden={hidden}
      >
        <option value="" hidden>
          Modelo
        </option>
        {filterModels
          .sort((name1: any, name2: any) =>
            name1.name < name2.name ? -1 : name1.name > name2.name ? 1 : 0,
          )
          .map((modelo: any) => (
            <option key={modelo.name} value={modelo.brandName === marca ? modelo.name : ''}>
              {modelo.brandName === marca ? modelo.name : ''}
            </option>
          ))}
      </select>
      <button hidden={hidden} onClick={handleSearch}>
        Buscar
      </button>
    </div>
  );
};
