import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { MainBanner, RegularBanners } from './components/BoxBanners';
import { mainBannerFormatted, regularBannerFormatted } from './components/utils/adapterBanners';

interface Props {
  data: any;
  locale?: any;
}

export const RenderSectionSeven: React.FunctionComponent<Props> = inject('locale')(
  observer(({ data, locale }) => {
    const mainBanner = mainBannerFormatted(data, locale.city.split('/')[0]);
    const regularBanners = regularBannerFormatted(data, locale.city.split('/')[0]);
    return (
      <div className="mt40 mt0-m mt0-l pb40 pb72-ns vw-100">
        <Grid className="mt40 mt0-m mt0-l flex justify-center">
          <GridCell width={[1, 7 / 12]} className="br10">
            <MainBanner data={mainBanner} />
          </GridCell>
          <GridCell width={[1, 5 / 12]} className="pl0 pl12-ns pl24-m pl24-l">
            <RegularBanners data={regularBanners} />
          </GridCell>
        </Grid>
      </div>
    );
  }),
);

export const SectionSeven = () => {
  const banners = graphql`
    query {
      allDirectusBanner {
        nodes {
          city {
            city_name
          }
          position
          link
          large_image {
            data {
              full_url
            }
          }
        }
      }
    }
  `;
  const renderSectionSevenBanners = (data: any) => {
    const {
      allDirectusBanner: { nodes },
    } = data;
    return <RenderSectionSeven data={nodes} />;
  };

  return <StaticQuery query={banners} render={renderSectionSevenBanners} />;
};
