import React from 'react';
import cx from 'classnames';
import styles from './SectionCreator.module.css';

interface Props {
  title: string;
  Spotlight: string;
}

export const TitleSection = ({ title, Spotlight }: Props) => {
  const titleClass = cx('m8 mb12-m', styles.titleClass);
  return (
    <div className={titleClass}>
      <h2 className="di f30-ns f26 fw2  abbey">
        {title} <span className="f30-ns f26 fw7 abbey">{Spotlight}</span>
      </h2>
    </div>
  );
};
