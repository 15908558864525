import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import filter from 'lodash/filter';
import { useMediaQuery } from 'react-responsive';
import { PARTS_NUMBER, SERVICES_NUMBER } from '../../utils/productType';
import { Button, WhiteOutlineButton } from '../Button';
import { Grid } from '../Grid';
import { GridCell } from '../GridCell';
import { Icon } from '../Icons';
import { LinkBlack } from '../Link';
import { Select } from '../Select';
import styles from './RefineSearch.module.css';

interface Values {
  initialYear: number;
  finalYear: number;
  price: any;
}

interface Props {
  initialPointing?: 'up' | 'down';
  textFirstLine: string;
  textSecondLine?: string;
  setRefineOpen: (is: boolean) => void;
  handleFilter: (data: Values) => void;
  defaultOpen?: boolean;
  searchResultNumber: number;
  handleSearch?: (value: any) => void;
  values: Values;
  setValues: (values: any) => void;
  currentYear: number;
  loading?: boolean;
  baseYear: number;
  selectedCategory: any;
  advancedSearchLink: string;
}

export const Refine = ({
  textFirstLine,
  textSecondLine,
  initialPointing = 'down',
  setRefineOpen,
  defaultOpen = false,
  handleFilter,
  handleSearch,
  values,
  setValues,
  currentYear,
  loading,
  baseYear,
  selectedCategory = 0,
  advancedSearchLink,
}: Props) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [didMount, setdidMount] = useState(true);
  const isSmallDevice = useMediaQuery({
    query: '(max-device-width: 374px)',
  });

  const refineMainClass = cx('relative-ns br3 pointer', styles.refineMainClass, {
    [styles.refineOpen]: isOpen,
    'br--bottom': initialPointing === 'down',
    'w-100': isOpen && initialPointing === 'down',
    [styles.refineWidth]: !isOpen && initialPointing === 'down',
    [styles.hidden]: selectedCategory === SERVICES_NUMBER || selectedCategory === PARTS_NUMBER,
  });

  const arrowDownClass = cx(
    'absolute right-0 bg-transparent bn white pointer',
    styles.arrowDownClass,
    {
      'top-0': initialPointing === 'up',
      'bottom-0': initialPointing === 'down',
      mb8: isOpen,
      [styles.arrowAreaClick]: !isOpen,
      'pr24 pt12-ns': !isOpen,
    },
  );

  const BigAreaClick = () => {
    if (!isOpen) {
      setIsOpen(!isOpen);
      setRefineOpen(!isOpen);
    }
  };

  const buttonClick = () => {
    if (isOpen) {
      setIsOpen(!isOpen);
      setRefineOpen(!isOpen);
    }
  };

  const handleTexts = () => {
    const textClass = cx(
      'ma0 ml16 mt8 mt0-ns ml12-ns mb12-ns absolute f12 fw6 white',
      styles.animationFadeInDelay4ms,
      {
        'bottom-0 left-0': initialPointing === 'down',
        'top-0 left-0': initialPointing === 'up',
      },
    );

    if (!isOpen) {
      if (initialPointing === 'down') {
        return (
          <p className={textClass}>
            {textFirstLine} <span className="f12 fw3 white">{textSecondLine}</span>
          </p>
        );
      }

      return (
        <div className={textClass}>
          <p className="ma0">{textFirstLine}</p>
          <span className="f12 fw3 white">{textSecondLine}</span>
        </div>
      );
    }
  };

  const handleIcon = () => {
    const initialState = initialPointing === 'up' ? 'SvgIconArrowDown' : 'SvgIconArrowUp';
    const secondState = initialPointing === 'up' ? 'SvgIconArrowUp' : 'SvgIconArrowDown';
    const icon = isOpen ? initialState : secondState;
    return <Icon name={icon} width={16} height={16} />;
  };

  const handleSelects = () => {
    const yearFromMOC = [];
    const valueMOC = [
      { label: 'Abaixo de 10.000', value: { initialPrice: 0, finalPrice: 10000 } },
      { label: 'De 10.000 a 20.000', value: { initialPrice: 10000, finalPrice: 20000 } },
      { label: 'De 20.000 a 30.000', value: { initialPrice: 20000, finalPrice: 30000 } },
      { label: 'De 30.000 a 40.000', value: { initialPrice: 30000, finalPrice: 40000 } },
      { label: 'Acima de 50.000', value: { initialPrice: 50000, finalPrice: 999999 } },
    ];

    for (let i = baseYear; i <= currentYear; i++) {
      yearFromMOC.push({ label: i.toString(), value: i });
    }

    yearFromMOC.reverse();

    useEffect(() => {
      if (didMount) {
        return setdidMount(false);
      }
      handleFilter(values);
    }, [values]);

    const handleSelectChange = (data: any, elem: any) => {
      const { name } = elem;
      const { value } = data;
      setValues({ ...values, [name]: value });
    };

    const valueClass = cx('z-5', styles.animationFadeInDelay4ms, styles.valueClass);
    const yearsClass = cx('z-4', styles.animationFadeInDelay4ms, styles.yearsClass);

    if (isOpen) {
      const yearsTo = filter(yearFromMOC, year => year.value >= values.initialYear);

      const startYearLabel = isSmallDevice ? 'Do ano' : 'Do ano modelo';

      const endYearLabel = isSmallDevice ? 'Até ano' : 'Até ano modelo';

      return (
        <Grid className="h-100-ns items-end-ns pa16 pl0-ns pt0 pb8-ns">
          <GridCell width={[1, 3 / 12]} className={valueClass}>
            <Select title="Valor" name="price" options={valueMOC} onChange={handleSelectChange} />
          </GridCell>
          <GridCell width={[1 / 2, 4 / 12]} className={yearsClass}>
            <Select
              title={startYearLabel}
              name="initialYear"
              options={yearFromMOC}
              onChange={handleSelectChange}
            />
          </GridCell>
          <GridCell width={[1 / 2, 4 / 12]} className={yearsClass}>
            <Select
              title={endYearLabel}
              name="finalYear"
              options={yearsTo}
              onChange={handleSelectChange}
            />
          </GridCell>
          <GridCell width={[0, 1 / 12]} />
        </Grid>
      );
    }
  };

  const handleClickSearch = () => {
    if (handleSearch) {
      handleSearch(selectedCategory);
    }
  };

  const handleButton = () => {
    const buttonDivClass = cx(
      'mh16 mh0-ns relative w-100-ns',
      {
        [styles.buttonPositionNegative]: initialPointing === 'up',
        [styles.linkPositionNegative]: initialPointing === 'up',
      },
      styles.animationFadeInDelay4ms,
    );

    if (isOpen) {
      if (initialPointing === 'down') {
        return (
          <div className={buttonDivClass}>
            <Button
              classButton="mt16"
              onClick={handleClickSearch}
              loading={loading}
              disabled={loading}
            >
              BUSCAR
            </Button>
          </div>
        );
      }

      return (
        <div className={buttonDivClass}>
          <WhiteOutlineButton className="absolute mb16" onClick={handleClickSearch}>
            BUSCAR
          </WhiteOutlineButton>
          <LinkBlack
            className="f14 fw6 bn white pointer underline w-100 flex justify-center pointer absolute pv8"
            resetClasses
            to={advancedSearchLink}
            title="Busca avançada"
          >
            Busca avançada
          </LinkBlack>
        </div>
      );
    }
  };

  const renderSeeCars = () => {
    const checkOpen = isOpen || (isOpen && selectedCategory !== 7);

    if (isOpen && selectedCategory === 7) {
      setIsOpen(false);
      setRefineOpen(false);
    }

    if (checkOpen) {
      return null;
    }

    return (
      <LinkBlack
        className="dn db-ns f14 fw6 bn white pointer underline w-100 outline-0 pointer tc lh-copy mt8 pv8"
        resetClasses
        to={advancedSearchLink}
        title="Busca avançada"
      >
        Busca avançada
      </LinkBlack>
    );
  };

  return (
    <>
      <div className={refineMainClass} onClick={BigAreaClick}>
        {handleTexts()}
        <button className={arrowDownClass} onClick={buttonClick} aria-label={textSecondLine}>
          {handleIcon()}
        </button>
        {handleSelects()}
      </div>
      {renderSeeCars()}
      {handleButton()}
    </>
  );
};
